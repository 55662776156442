export enum ToolbarType {
  FONT_CHANGE = "font-change",
  SIZE_ADD = "size-add",
  SIZE_MINUS = "size-minus",
  BOLD = "bold",
  ITALIC = "italic",
  UNDERLINE = "underline",
  STRIKEOUT = "strikeout",
  COLOR = "color",
  HIGHLIGHT = "highlight",
  CLEAN = "clean",
  ALIGN_AUTO = "align-auto",
  ALIGN_LEFT = "align-left",
  ALIGN_CENTER = "align-center",
  ALIGN_RIGHT = "align-right",
  ALIGN_JUSTIFY = "align-justify",
}
