import { EDITOR_PREFIX } from "../../dataset/constant/Editor";
import { debounce } from "../../utils";
import type { Draw } from "../draw/Draw";
import type { CanvasEvent } from "../event/CanvasEvent";
import { pasteByEvent } from "../event/handlers/paste";

export class CursorAgent {
  private draw: Draw;
  private container: HTMLDivElement;
  private agentCursorDom: HTMLTextAreaElement;
  private canvasEvent: CanvasEvent;

  constructor(draw: Draw, canvasEvent: CanvasEvent) {
    this.draw = draw;
    this.container = draw.getContainer();
    this.canvasEvent = canvasEvent;
    // 代理光标绘制
    const agentCursorDom = document.createElement("textarea");
    agentCursorDom.autocomplete = "off";
    agentCursorDom.classList.add(`${EDITOR_PREFIX}-inputarea`);
    agentCursorDom.innerText = "";
    this.container.append(agentCursorDom);
    this.agentCursorDom = agentCursorDom;
    // 事件
    agentCursorDom.onkeydown = (evt: KeyboardEvent) => this._keyDown(evt);
    agentCursorDom.oninput = debounce(this._input.bind(this), 0);
    agentCursorDom.onpaste = (evt: ClipboardEvent) => this._paste(evt);
    agentCursorDom.addEventListener(
      "compositionstart",
      this._compositionstart.bind(this),
    );
    agentCursorDom.addEventListener(
      "compositionend",
      this._compositionend.bind(this),
    );
  }

  public getAgentCursorDom(): HTMLTextAreaElement {
    return this.agentCursorDom;
  }

  private _keyDown(evt: KeyboardEvent) {
    this.canvasEvent.keydown(evt);
  }

  private _input(evt: Event) {
    const data = (evt as InputEvent).data;
    if (!data) {
      return;
    }
    this.canvasEvent.input(data);
  }

  private _paste(evt: ClipboardEvent) {
    const isReadonly = this.draw.isReadonly();
    if (isReadonly) {
      return;
    }
    const clipboardData = evt.clipboardData;
    if (!clipboardData) {
      return;
    }
    pasteByEvent(this.canvasEvent, evt);
    evt.preventDefault();
  }

  private _compositionstart() {
    this.canvasEvent.compositionstart();
  }

  private _compositionend(evt: CompositionEvent) {
    this.canvasEvent.compositionend(evt);
  }
}
