export const NAME_PLACEHOLDER = {
  SELECTED_TEXT: "%s",
};

export const INTERNAL_CONTEXT_MENU_KEY = {
  GLOBAL: {
    CUT: "globalCut",
    COPY: "globalCopy",
    PASTE: "globalPaste",
    SELECT_ALL: "globalSelectAll",
    PRINT: "globalPrint",
    AUTO_ADJUST_SIZE: "globalAutoAdjustSize",
  },
  CONTROL: {
    DELETE: "controlDelete",
  },
  HYPERLINK: {
    DELETE: "hyperlinkDelete",
    CANCEL: "hyperlinkCancel",
    EDIT: "hyperlinkEdit",
  },
  IMAGE: {
    CHANGE: "imageChange",
    SAVE_AS: "imageSaveAs",
    TEXT_WRAP: "imageTextWrap",
    TEXT_WRAP_EMBED: "imageTextWrapEmbed",
    TEXT_WRAP_UP_DOWN: "imageTextWrapUpDown",
    TEXT_WRAP_SURROUND: "imageTextWrapSurround",
    TEXT_WRAP_FLOAT_TOP: "imageTextWrapFloatTop",
    TEXT_WRAP_FLOAT_BOTTOM: "imageTextWrapFloatBottom",
    INSERT: "imageInsert",
  },
  TABLE: {
    BORDER: "border",
    BORDER_ALL: "tableBorderAll",
    BORDER_EMPTY: "tableBorderEmpty",
    BORDER_DASH: "tableBorderDash",
    BORDER_EXTERNAL: "tableBorderExternal",
    BORDER_INTERNAL: "tableBorderInternal",
    BORDER_TD: "tableBorderTd",
    BORDER_TD_TOP: "tableBorderTdTop",
    BORDER_TD_RIGHT: "tableBorderTdRight",
    BORDER_TD_BOTTOM: "tableBorderTdBottom",
    BORDER_TD_LEFT: "tableBorderTdLeft",
    BORDER_TD_FORWARD: "tableBorderTdForward",
    BORDER_TD_BACK: "tableBorderTdBack",
    VERTICAL_ALIGN: "tableVerticalAlign",
    VERTICAL_ALIGN_TOP: "tableVerticalAlignTop",
    VERTICAL_ALIGN_MIDDLE: "tableVerticalAlignMiddle",
    VERTICAL_ALIGN_BOTTOM: "tableVerticalAlignBottom",
    INSERT_ROW_COL: "tableInsertRowCol",
    INSERT_TOP_ROW: "tableInsertTopRow",
    INSERT_BOTTOM_ROW: "tableInsertBottomRow",
    INSERT_LEFT_COL: "tableInsertLeftCol",
    INSERT_RIGHT_COL: "tableInsertRightCol",
    DELETE_ROW_COL: "tableDeleteRowCol",
    DELETE_ROW: "tableDeleteRow",
    DELETE_COL: "tableDeleteCol",
    DELETE_TABLE: "tableDeleteTable",
    MERGE_CELL: "tableMergeCell",
    CANCEL_MERGE_CELL: "tableCancelMergeCell",
  },
};
